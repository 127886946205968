.filters-container {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
  }
  
  .filter-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .filter-item {
    margin-bottom: 8px;
  }
  
  .reset-button-container {
    position: relative; /* Added relative positioning to the container */
  }
  
  .button-style {
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 6px 16px; /* Adjusted padding for vertical centering */
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  
  
  .reset-button:hover {
    background-color: #d32f2f;
  }
  
  .filepond-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
  }
  